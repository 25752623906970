// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

//= require jquery3
//= require popper
//= require bootstrap
//= require rails-ujs

import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

//import "controllers"
import "trix"

import "@rails/actiontext"

import TomSelect from "tom-select"
//divなのをpタグに
Trix.config.blockAttributes.default = {
  tagName: "p",
  breakOnReturn: true
}

Trix.config.blockAttributes.heading1 = {
  tagName: "h2"
}
console.log(Trix.config)
/* what the newly created button does */
Trix.config.textAttributes.red = {
  style: { color: "red" },
  parser: function(element) {
      return element.style.color === "red"
  },
  inheritable: true
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" data-trix-attribute="red">RED</button>'

  event.target.toolbarElement.
  querySelector(".trix-button-group").
  insertAdjacentHTML("beforeend", buttonHTML)
})

if(document.getElementById("tag_id") != null){
  new TomSelect('#tag_id', {
  });
};

new TomSelect('#archive_related_tag_id', {
});

new TomSelect('#archive_tag_ids', {
  plugins: ["remove_button"],
  create: function(input, callback) {
        const data = { name: input }
        const token = document.querySelector('meta[name="csrf-token"]').content
        fetch('/admins/tags', {
          method: 'POST',
          headers:  {
            "X-CSRF-Token": token,
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          body: JSON.stringify(data)
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          callback({value: data.id, text: data.name })
        });
      },
});

